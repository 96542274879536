<template>
    <Defaultlayout :userprofile="userprofile">
        <section class="basket edit-wishlist">
                <div class="container">
                    <!-- Display selected store -->
                    <div class="order-box">
                        <!-- Display a selected store -->
                        <div class="profile-id" v-if="wishliststoredetails!=null">
                            <div class="media">
                                <div class="ordered-profile mr-3">
                                    <img
                                        :src="wishliststoredetails.storelogo"
                                        alt="..."
                                        v-if="wishliststoredetails.storelogo!=''"
                                    >
                                    <avatar
                                        :fullname="wishliststoredetails.storename.split(' ')[0]"
                                        :size="80"
                                        :radius="0" 
                                        :color="avatarBackgroundColor"
                                        v-if="wishliststoredetails.storelogo==''"
                                    ></avatar>
                                </div>
                                <div class="media-body">
                                    <div class="row form-row">
                                        <div class="col-sm-7 order-description">
                                            <h5 class="d-inline-block w-auto">{{wishliststoredetails.storename}}</h5>
                                            <a href="javascript:void(0);" @click="openStore()" :style="textcolor" class="d-inline-block ml-3">Change</a>
                                            <h6 v-if="wishliststoredetails.categoryname!=null">{{wishliststoredetails.categoryname}}</h6>
                                            <a class="location" href="javascript:void(0);">
                                                <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                                                <span v-if="wishliststoredetails.areaname"> {{wishliststoredetails.areaname}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="backshare d-flex flex-wrap align-items-center ml-auto">
                                    <a href="javascript:void(0)" @click="goBack" class="d-flex flex-wrap align-items-center">
                                        <img src="../../assets/images/pre-slider-btn.png">
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <!-- select a store -->
                    <div v-if="wishliststoredetails == null">
                        <div>
                            <div>
                                <div class="add-button">
                                    <button
                                    type="button"
                                    class="d-block"
                                    data-toggle="modal"
                                    @click="openStore()"
                                    >
                                    <span class="d-block">
                                        <img src="../../assets/images/svgs/plus.svg" alt="plus" class="d-block">
                                    </span>
                                    </button>
                                </div>
                                <span><p>Select a store</p></span>
                            </div>
                        </div>
                    </div>

                    <div class="your-basket">
                        <div class="d-sm-flex justify-content-sm-between flex-wrap">
                            <h2 class="section-heading d-inline-block">Your Wishlist ({{totalWishlistItemQty}} items)</h2>
                            <div class="your-basket-right d-flex justify-content-between">
                                <div class="add-more-btn ml-3">
                                    <a href="javascript:void(0);" :style="colorObj" class="btn button-style hoverEffect" @click="selectStoreItem(wishliststoredetails.storeid)" :class="wishliststoredetails == null ? 'disabled':''" style="padding:7px;">Add Items</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="basket-order col-lg-8 mt-4 mt-lg-0" v-if="wishlist_store_item == null">
                                <div class="table-responsive basket-list">
                                    <div class="empty-box text-center">
                                    <div class="contents">
                                        <div class="empty-image">
                                        <img src="../../assets/images/svgs/empty-box.svg" alt="empty-box">
                                        </div>
                                    <h3>No Records Found</h3>
                                    </div>
                                    <a href="javascript:void(0);" :style="colorObj" class="btn button-style hoverEffect" @click="selectStoreItem(wishliststoredetails.storeid)" :class="wishliststoredetails == null ? 'disabled':''" style="padding:7px;">Add Items</a>
                                    </div>
                                </div>
                            </div>
                            <div class="basket-order col-lg-8 mt-4 mt-lg-0" v-if="wishlist_store_item != null">
                                <div class="table-responsive basket-list">
                                    <p class="table-title d-md-none"></p>
                                    <table class="table table-borderless" border="0">
                                        <thead>
                                            <tr>
                                                <th class="order-item-main">Items</th>
                                                <th class="unit-price-main">Unit Price</th>
                                                <th class="quantity-main">Quantity</th>
                                                <th class="close-main"></th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody v-for="(wishlist_item,index) in wishlist_store_item" :key="index">
                                            <tr class="first-item" v-for="(product,index) in getAddedWishlistItems(wishlist_item.item_variants)" :key="index">
                                                <td class="order-item">
                                                    <div class="d-flex">
                                                        <div class="ordered-profile mr-3">
                                                            <img :src="wishlist_item.item_image" />
                                                        </div>
                                                        <div class="order-description d-flex flex-wrap align-items-center">
                                                            <h5 class="w-100 mb-2" v-if="wishlist_item.category_name!=null && wishlist_item.sub_category_name!=null">{{ wishlist_item.item_name }}({{wishlist_item.category_name}} / {{wishlist_item.sub_category_name}})</h5>
                                                            <h5 v-else class="w-100 mb-2">{{ wishlist_item.item_name }}</h5>
                                                            <h6 class="w-100 mb-2 text-black">
                                                                <span v-if="parseFloat(product.unit_quantity) != '0'">
                                                                    {{ Number( product.unit_quantity ).toFixed(1) }}
                                                                </span>
                                                                <span v-if="product.unit_name != null">
                                                                    {{ product.unit_name }}
                                                                </span>
                                                                <span v-if="product.packaging_name != null">
                                                                    {{ product.packaging_name }}
                                                                </span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="unit-price valign-middle">
                                                    <span>
                                                        <h5 class="price">
                                                            <span>{{ product.item_price }}</span>
                                                        </h5>
                                                    </span>
                                                </td>
                                                <td class="valign-middle quantity-main">
                                                    <span>
                                                        {{ parseFloat(wishlist_item.quantity).toFixed(0) }}
                                                    </span>
                                                </td>
                                                <td class="valign-middle close-main d-none d-md-table-cell">
                                                    <button class="close">&times;</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="basket-payment col-lg-4 mt-4 mt-lg-0">
                                <form class="wishlist-reminder-form available-option" method="post" @submit.prevent="saveWishlist">
                                <ul class="payment-details-wrap list-unstyled">
                                    <li class="d-flex justify-content-between mt-3">
                                        <input 
                                            type="text" 
                                            name="wishlistname" 
                                            placeholder="Wishlist Name" 
                                            class="offer-input mb-0 form-control" 
                                            v-model="wishlist_name" 
                                            v-validate="'required'"
                                            :class="{error:errors.has('wishlistname')}">
                                        <span v-show="errors.has('wishlistname')" class="help is-danger">Enter Wishlist Name</span>
                                    </li>
                                    <li class="d-flex flex-wrap justify-content-between delivery-charge wishlist-reminder-box">
                                        <h5>Wishlist Reminder</h5>
                                        <span class="tax-and-charges cart-section" title="You will get a reminder thru notification alert for the Wish-list as per the settings here. Cart will not be created automatically from Wish-list and can bee manually created anytime."><b><img class="wishlist-info" src="../../assets/images/svgs/infoicon.svg"/></b></span>
                                        <div class="d-flex flex-wrap justify-content-between align-items-center w-100 wishlist-remainer">
                                            <div class="form-row">
                                                <div class="d-flex flex-wrap radio-dt">
                                                <input 
                                                    type="radio" 
                                                    id="daily" 
                                                    name="wishlist_reminder" 
                                                    value="1"
                                                    v-model="wishlist_reminder">
                                                    <label for="daily">Daily</label>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="d-flex flex-wrap radio-dt">
                                                <input 
                                                    type="radio" 
                                                    id="weekly" 
                                                    name="wishlist_reminder" 
                                                    value="2" 
                                                    v-model="wishlist_reminder">
                                                    <label for="weekly">Weekly</label>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="d-flex flex-wrap radio-dt">
                                                    <input 
                                                        type="radio" 
                                                        id="monthly" 
                                                        name="wishlist_reminder" 
                                                        value="3" 
                                                        v-model="wishlist_reminder">
                                                        <label for="monthly">Monthly</label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <div class="date-timepicker-main d-flex flex-wrap" v-if="wishlist_reminder == 1">
                                        <div class="form-group date-timepicker scheduled-time w-100">
                                            <input 
                                                type="time" 
                                                name="scheduled_time" 
                                                id="scheduled_time" 
                                                class="w-100" 
                                                v-model="scheduled_wishlist_daily">
                                                <label for="time"></label>
                                        </div>
                                    </div>

                                    <div v-if="wishlist_reminder == 2">
                                        <select v-model="scheduled_wishlist_weekly" class="w-100 mb-3 form-control">
                                            <option value="">Select Day of Week</option>
                                            <option
                                            v-for="(scheduled_wishlist_weekly,index) in weekofday" :key="index"
                                            :value="scheduled_wishlist_weekly.value"
                                            > {{ scheduled_wishlist_weekly.day }}</option>
                                        </select>
                                    </div>

                                    <div v-if="wishlist_reminder == 3">
                                        <select v-model="scheduled_wishlist_monthly" class="w-100 mb-3 form-control">
                                            <option value="">Select Day of Month</option>
                                            <option>Monthly on day {{ getdate }}</option>
                                            <option>Monthly on the {{week}}</option>
                                        </select>
                                    </div>
                                    <li class="button row mx-0 justify-content-center">
                                        <button :style="colorObj" class="btn button-style check-out hoverEffect">Save Wishlist</button>
                                        <router-link :to="'/profile#wishlist'" class="text-danger mt-2">Cancel</router-link>
                                    </li>
                                </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <div class="container" v-if="loading==true">
            <p class="text-center">
                <img src="../../assets/images/loading.gif" />
            </p>
        </div>
        <div
            class="modal fade"
            id="WishlistStoreModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="WishlistStoreModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <selectwishliststore></selectwishliststore>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade wishlist-store-item"
            id="WishlistStoreItemModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="WishlistStoreItemModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <h2 class="page-heading text-center">ADD ITEM TO WISHLIST</h2>
                        <selectwishlistitem v-if="category.store_id!=0" :storeid="category.store_id"></selectwishlistitem>
                    </div>
                </div>
            </div>
        </div>
        
    </Defaultlayout>
</template>

<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import moment from 'moment';
import helper from '../../store/helper';
import SelectWishlistStore from "../wishlist/SelectWishlistStore";
import SelectWishlistItem from "../wishlist/SelectWishlistItem";
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
export default {
    name:"Editwishlist",
    components:{
        Defaultlayout,
        Avatar,
        "selectwishliststore": SelectWishlistStore,
        "selectwishlistitem": SelectWishlistItem,
    },
    mixins:[helper],
    data(){
        return {
            userprofile:null,
            loading:true,
            wishlist_reminder: 1,
            scheduled_wishlist_daily:"",
            scheduled_wishlist_weekly:"",
            scheduled_wishlist_monthly:"",
            wishlist_name:"",
            date: "",
            currentOffset:"",
            istoffset: 330,
            isttime:"",
            getdate:"",
            adjustdate:"",
            prefixes:"",
            week:"",
            currentweekdate:"",
            weekofday:[
                {
                    "day": "Every Monday",
                    "value": 0
                },
                {
                    "day": "Every Tuesday",
                    "value": 1
                },
                {
                    "day": "Every Wednesday",
                    "value": 2
                },
                {
                    "day": "Every Thursday",
                    "value": 3
                },
                {
                    "day": "Every Friday",
                    "value": 4
                },
                {
                    "day": "Every Saturday",
                    "value": 5
                },
                {
                    "day": "Every Sunday",
                    "value": 6
                },
            ],
            wishlist:{
                user_id:"",
                store_id:"",
                wishlist_name:"",
                reminder_type:"",
                dayofweek:"",
                items:"",
                daily_time:"",
                monthly_date:""
            },
            wishliststoredetails: null,
            avatarBackgroundColor:"",
            avatarTextColor:"",
            getcategorylist:null,
            category:{
                store_id:0
            },
            wishlist_store_item:null,
            wishlist_store_items:[],
            user_wishlist_item:[],
            edit_wishlist:{
                wishlist_id:""
            }
        }
    }, 
    computed:{
        totalWishlistItemQty(){
            var total_qty = 0;
            if(localStorage.getItem("edit_wishlist")!=null){
                var data = JSON.parse(localStorage.getItem("edit_wishlist"));
                total_qty = data.item_count;
            }
            return total_qty;
        }
    },
    mounted(){
        this.loading = true;
        if(localStorage.getItem("edit_wishlist")!=null){
            var _this = this;
            this.edit_wishlist.wishlist_id = this.$route.query.wishlist_id;
            this.$store.dispatch("customerwishlistdetails", this.edit_wishlist).then(response => {
                if(response.status == 1){
                    var data = response.data;
                    var storeObj = {};
                    storeObj["storename"] = data.store_name;
                    storeObj["storelogo"] = data.store_logo;
                    storeObj["areaname"] = data.area_name + ", " +data.city_name + ", "+data.state_name + ", "+data.zipcode;
                    _this.wishliststoredetails = storeObj;
                    _this.wishlist_store_item = data.items;
                    var storeItemObj = {};
                    data.items.forEach(variant => {
                        storeItemObj["item_id"] = variant.item_id;
                        storeItemObj["item_variants_id"] = variant.item_variants_id;
                        storeItemObj["quantity"] = variant.quantity;
                        _this.user_wishlist_item.push(storeItemObj);    
                    });
                    _this.wishlist_store_items = JSON.stringify(this.user_wishlist_item);
                    _this.category.store_id = data.store_id;
                    _this.wishlist_name = data.wishlist_name;
                    _this.scheduled_wishlist_daily = data.daily_time;
                }
            });
            this.loading = false;
        }
        bus.$on("wishlist-store-obj", data => {
            if(Object.keys(data).length!=0){
                this.wishliststoredetails = data;
            }
        });
        bus.$on("add-to-wishlist", data => {
            this.wishlist_store_item = data;
            this.wishlist_store_item.forEach(item => {
                var obj = {};
                obj["item_id"] = item.item_id;
                obj["item_variants_id"] = item.item_variants_id;
                obj["quantity"] = item.quantity;
                this.user_wishlist_item.push(obj);
            });
            this.wishlist_store_items = JSON.stringify(this.user_wishlist_item);
        });
        if (localStorage.getItem("user") != null) {
            this.userprofile = JSON.parse(localStorage.getItem("user"));
        } else {
            this.userprofile = null;
            window.location.href="/";
        }
        this.originalColor();
        this.date = new Date();
        this.currentOffset = this.date.getTimezoneOffset();
        this.getdate = this.date.getDate();
        this.isttime = new Date(this.date.getTime() + (this.istoffset + this.currentOffset)*60000);
        this.scheduled_wishlist_daily = this.scheduled_wishlist_daily!='' ? ('0' + this.isttime.getHours()).slice(-2) + ':' + ('0' + (this.isttime.getMinutes())).slice(-2) : "08:00";
        this.getWeekOfMonth(this.date);
        var month_week = this.week;	
        var momnetdate = moment(this.date);
        var week_day = momnetdate.day();
        this.getDaysofMonth(month_week,week_day)
    },
    updated(){
        this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
        this.avatarTextColor = this.cancelReOrderButton.color;
        window.$('.edit-wishlist .avatar table tbody tr td').css({ color: this.avatarTextColor});
    },
    methods:{
        getAddedWishlistItems(variants){
            return variants.filter(
                function(elem) {
                if (elem.is_added == "1") return elem;
                }
            );
        },
        getWeekOfMonth(date) {
            var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
            this.prefixes = ['first', 'second', 'third', 'fourth', 'fifth'];
            this.week = this.prefixes[Math.floor(date.getDate() / 7)] + ' ' + days[date.getDay()];
            return this.week;
        },
        getDaysofMonth(week,dayofweek){
            var weekdate = moment(this.date).startOf("month").day(dayofweek);
            this.currentweekdate = weekdate.add(7 * (week - 1), "days");
            this.currentweekdate = moment(this.currentweekdate).format('dddd');
        },
        goBack(){
            this.$router.go(-1);
        },
        openStore(){
            window.$('#WishlistStoreModal').modal('show');
        },
        selectStoreItem(storeid){
            window.$('#WishlistStoreItemModal').modal('show');
            this.category.store_id = storeid;
        },
        saveWishlist(){
            this.$validator.validateAll().then(result => {
                if(result){
                    this.loading = true;
                    this.wishlist.user_id = this.userprofile!=null ? this.userprofile.user_id : "0";
                    this.wishlist.store_id = this.category.store_id;
                    this.wishlist.items = this.wishlist_store_items;
                    this.wishlist.wishlist_name = this.wishlist_name;
                    this.wishlist.reminder_type = this.wishlist_reminder;
                    if(this.wishlist.reminder_type == 1){
                        this.wishlist.dayofweek = 0;
                        this.wishlist.daily_time = this.scheduled_wishlist_daily;
                        this.wishlist.monthly_date = "";
                    }
                    if(this.wishlist.reminder_type == 2){
                        this.wishlist.dayofweek = this.scheduled_wishlist_weekly;
                        this.wishlist.daily_time = "00:00";
                        this.wishlist.monthly_date = "";
                    }
                    if(this.wishlist.reminder_type == 3){
                        this.wishlist.dayofweek = 0;
                        this.wishlist.daily_time = "00:00";
                        this.wishlist.monthly_date = ('0' + this.scheduled_wishlist_monthly.replace(/[^0-9]/g, '')).slice(-2) + '-' + ('0' + (this.date.getMonth()+1)).slice(-2);
                    }
                    this.$store
                        .dispatch("customermanagewishlist", this.wishlist)
                        .then(response => {
                            if(response.status == 0){
                                this.$toast.error(response.message);
                            } else {
                                this.loading = false;
                                this.$router.push('/profile#wishlist')
                                this.$toast.success(response.message);
                            }
                        })
                }
            })
        },
        redirectToStore(){
            this.$router.push('search');
        }
    }
}
</script>